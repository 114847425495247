<template>
  <div class="goods_wrap" @mouseenter="show = true" @mouseleave="show = false">
    <div class="mll-box" v-if="item.grossmargin">
      <img :src="mllicon" alt="" />
      <div class="mll-text">{{ item.grossmargin }}</div>
    </div>
    <div class="hide-bottom"></div>
    <div class="img" @click="handleDetail">
      <img :src="item.logo" alt="" />
      <el-image class="left-top-icon" fit="fill" :src="item.icon"></el-image>
      <!-- <img class="left-top-icon" :src="item.icon" alt="" /> -->
    </div>
    <div class="goods_con">
      <p class="title" @click="handleDetail">{{ item.title }}</p>
      <p>规格：{{ item.sku }}</p>
      <p>厂家：{{ item.scqy }}</p>
      <p>效期：{{ item.validity_date }}</p>
      <div class="kcxl">
        <span>库存：{{ item.kc_str }}</span>
        <div class="btn_xl" v-if="Number(item.regular_num) > 0">
          <p>固定抢购量</p>
          <p class="num">{{ item.regular_num }}</p>
        </div>
      </div>
      <!-- <p>库存：{{ item.kc_str }}</p> -->
      <div class="dashedLine"></div>
      <div class="price-box">
        <div class="line-price-box">
          会员价：<span class="line-price">{{ item.line_price }}</span>
        </div>
        <div class="ms-price">
          秒杀价：<span class="red-price">{{ item.price }}</span>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px">
        <el-tooltip class="item" effect="dark" :content="`剩余${item.residue_num}`" placement="top">
          <el-progress class="hide-percentage" style="width: 110px" :text-inside="true" :percentage="item.percentage" status="exception" :stroke-width="12"></el-progress>
        </el-tooltip>

        <div v-if="item.show_cart == 101" class="ms-btn" @click.stop="addToCart">立即购买</div>
        <div v-if="item.show_cart != 101 && item.residue_num != 0" class="ms-btn-not-buy">{{ item.price }}</div>
        <div v-if="item.residue_num == 0" class="ms-btn-not">已抢光</div>
      </div>
    </div>
  </div>
</template>

<script>
// import tagcom from "@/components/tag.vue";
import icon from "@/assets/img/goods/icon.png";
import hot_bk from "@/assets/img/goods/hot_bk.png";
export default {
  components: {
    // tagcom,
  },
  // props: {
  //   item: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  props: ["itemData"],
  data() {
    return {
      show: false,
      //   msFlag: false,
      //   hgFlag: false,
      //   mzFlag: false,
      //   tjFlag: false,
      //   mjFlag: false,
      mllicon: icon,
      hot_bk: hot_bk,

      number: 10,
      item: {
        max_num: 0,
        min_num: 1,
        step: 1,
        number: 1,
      },
    };
  },
  watch: {
    itemData: {
      handler(newVal) {
        this.item = newVal;
        // console.log("初始化的商品信息-----", this.item);
        // if (this.item.min_num) {
        //   this.$set(this.item, "number", this.item.min_num);
        //   console.log("初始化的商品数量-----", this.item.number);
        // } else {
        //   this.$set(this.item, "number", 1);
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // console.log("单条商品信息----", this.item);
    // if (this.item.min_num) {
    //   this.$set(this.item, "number", this.item.min_num);
    // } else {
    //   this.$set(this.item, "number", 1);
    // }
    // if (this.item.tags && this.item.tags.length > 0) {
    //   let hasMS = this.item.tags.filter((v) => {
    //     return v.title == "秒杀";
    //   });
    //   if (hasMS.length > 0) {
    //     this.msFlag = true;
    //   }
    //   let hasHG = this.item.tags.filter((v) => {
    //     return v.title == "换购";
    //   });
    //   if (hasHG.length > 0) {
    //     this.hgFlag = true;
    //   }
    //   let hasMZ = this.item.tags.filter((v) => {
    //     return v.title == "买赠";
    //   });
    //   if (hasMZ.length > 0) {
    //     this.mzFlag = true;
    //   }
    //   let hasTJ = this.item.tags.filter((v) => {
    //     return v.title == "特价";
    //   });
    //   if (hasTJ.length > 0) {
    //     this.tjFlag = true;
    //   }
    //   let hasMJ = this.item.tags.filter((v) => {
    //     return v.title == "单品满减";
    //   });
    //   if (hasMJ.length > 0) {
    //     this.mjFlag = true;
    //   }
    // }
  },

  methods: {
    handleDetail() {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: this.item.id },
      });
      window.open(href, "_blank");
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.regular_num,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        // this.addToCart
        this.$emit("getData");
        this.$store.dispatch("getBadge");
      });
    },
    collectClick() {
      console.log();
      if (this.item.is_collected == 102) {
        this.$api("goods.addCollect", { id: this.item.id }).then(() => {
          this.$message.success("收藏成功");
          this.item.is_collected = 101;
        });
      } else {
        this.$api("goods.delCollect", { id: this.item.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.item.is_collected = 102;
        });
      }
    },

    blur(item) {
      console.log(item);
      if (Number(this.item.number) >= Number(this.item.max_num)) {
        this.$message.error(`该商品最大购买量为${this.item.max_num}`);
        this.item.number = Math.floor(Number(this.item.max_num) / Number(this.item.step)) * Number(this.item.step);
      }
      if (Number(this.item.number) < Number(this.item.min_num)) {
        this.$message.error(`该商品最小购买量为${this.item.min_num}`);
        this.item.number = Number(this.item.min_num);
      }
      if (Number(this.item.number) % 1 !== 0) {
        let num = Math.ceil(Number(this.item.number));
        if (num > Number(this.item.max_num)) {
          this.$message.error(`该商品最大购买量为${this.item.max_num}`);
          this.item.number = Number(this.item.max_num);
        } else {
          this.item.number = num;
        }
      }
    },
    changeNum(e) {
      console.log("------", e);
    },
  },
};
</script>

<style lang="less" scoped>
.goods_wrap {
  background-color: #fff;
  width: 230px;
  //   border: 1px solid #dddddd;
  // border: 1px solid red;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  // box-shadow: inset 0 0 10px #d20817;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgb(84, 85, 85);
  }
  .hide-bottom {
    width: 100%;
    height: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }
  .mll-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 89px;
    height: 76px;
    z-index: 10;
    .mll-text {
      position: absolute;
      top: 48px;
      left: 5px;
      width: 70px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }

  .img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background: #eeeeee;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;

    .cuxiao {
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 230px;
      padding: 3px 10px;
      box-sizing: border-box;
      background: #000000;
      opacity: 0.65;
      font-size: 12px;
      color: #fff;
    }
    .left-top-icon {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 60px;
      //   width: 50px !important;
      //   height: 56px !important;
    }
  }

  .goods_con {
    padding: 0 10px;
    // width: calc(100% - 15px);
    width: 210px;
    p {
      margin-top: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap !important;
      font-size: 13px;
    }
    .title {
      font-size: 14px;
      color: #4e4e4e;
      font-weight: 700;
    }
    .kcxl {
      display: flex;
      align-items: center;
      margin-top: 6px;
      .btn_xl {
        background-image: linear-gradient(to right, #ff512f 0%, #ff525c 51%, #ff512f 100%);
        color: #fff;
        padding: 1px 2px;
        border-radius: 5px;
        font-size: 12px;
        display: flex;
        margin-left: 10px;
        align-items: center;
        p {
          font-size: 12px;
          margin-top: 0;
        }
        .num {
          background-color: #fff;
          color: #f54c4c;
          font-size: 12px;
          padding: 0 4px;
          height: 14px;
          line-height: 14px;
          border-radius: 3px;
          margin-left: 5px;
        }
      }
    }
    .dashedLine {
      margin-top: 15px;
      border-top: 1px dashed #ccc;
    }
    .price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      .line-price-box {
        font-size: 12px;
        color: #333;
        .line-price {
          text-decoration: line-through;
        }
      }
      .ms-price {
        font-size: 12px;
        color: #333;
        .red-price {
          font-size: 22px;
          font-weight: bold;
          color: red;
        }
      }
    }
    .ms-btn {
      width: 90px;
      line-height: 30px;
      height: 30px;
      padding: 0;
      color: #e01222;
      border: 1px solid #e01222;
      border-radius: 0;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.25s;
      border-radius: 8px;

      &:hover {
        background-color: #f2c8cc;
      }
    }
    .ms-btn-not-buy {
      width: 90px;
      line-height: 30px;
      height: 30px;
      padding: 0;
      color: #919090;
      border: 1px solid #ded9d9;
      border-radius: 0;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.25s;
      border-radius: 8px;
    }
    .ms-btn-not {
      width: 90px;
      line-height: 30px;
      height: 30px;
      padding: 0;
      color: #999;
      border: 1px solid #999;
      border-radius: 0;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.25s;
      border-radius: 8px;
    }
    /deep/.hide-percentage .el-progress__text {
      display: none;
    }
  }
}
/deep/.el-progress-bar__innerText {
  display: none;
}
</style>
