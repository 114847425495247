<template>
  <div class="activity_container" :style="`background: ${info.bj_color}`">
    <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div>
    <div class="active-content">
      <div class="head" v-if="hot_area.length > 0">
        <div class="tab-box">
          <div :class="['tab-item', activeInd == index ? 'active' : '']" @click="changeTab(item, index)" v-for="(item, index) in hot_area" :key="index">
            <p class="ms-time">{{ item.time_str }}</p>
            <p class="ms-title">{{ item.countdown_title }}</p>
            <p>{{ item.djs }}</p>
          </div>
        </div>
      </div>
      <div class="content-center">
        <!-- 爆款秒杀 -->
        <!-- <div class="goods_wrap" v-if="hot_goods.length > 0">
          <div class="item" v-for="k in hot_goods" :key="k.id">
            <ms_item :itemData="k" />
          </div>
        </div> -->
        <!-- 轮播展示爆款秒杀 -->
        <div class="swiper-box" v-if="hot_area_2d.length > 0">
          <el-carousel class="swiper-content" height="650px;">
            <el-carousel-item class="swiper-item" v-for="(item, index) in hot_area_2d" :key="index">
              <div class="goods-item" v-for="(k, ind) in item" :key="ind">
                <div class="goods-img">
                  <img :src="k.logo" class="goods-img" alt="" />
                </div>
                <div class="right-info">
                  <div class="title-name">{{ k.title }}</div>
                  <div class="sub-text">规格：{{ k.sku }}</div>
                  <div class="sub-text">效期：{{ k.validity_date }}</div>
                  <div class="sub-text">厂家：{{ k.pzwh }}</div>
                  <div class="sub-text two-row">
                    <div>库存：{{ k.kc_str }}</div>
                    <!-- v-if="Number(k.regular_num) > 0" -->
                    <div class="btn_xl" v-if="Number(k.regular_num) > 0">
                      <p>固定抢购量</p>
                      <p class="num">{{ k.regular_num }}</p>
                    </div>
                  </div>
                  <div class="percentage-box">
                    <el-tooltip class="item" effect="dark" :content="`剩余${k.residue_num}`" placement="top">
                      <el-progress class="hide-percentage" style="width: 197px" :text-inside="true" :percentage="item.percentage" status="exception" :stroke-width="12"></el-progress>
                    </el-tooltip>
                  </div>
                  <div class="ms-btn">
                    <span>秒杀价￥</span>
                    <span>{{ k.price }}</span>
                    <div class="cart-img" v-if="k.show_cart == 101" @click.stop="addToCart">
                      <img src="../../assets/img/goods/bkms_cart.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="limit-text">
            <i class="el-icon-warning"></i>
            {{ limit_str }}
          </div>
        </div>

        <!-- 限时秒杀 -->
        <div class="tag-box" v-if="seckill_area.length > 0">
          <img :src="tag_img" alt="" />
          <p class="tag-title">限时秒杀</p>
        </div>
        <div class="goods_wrap" v-if="seckill_area.length > 0">
          <div class="item" v-for="item in seckill_area" :key="item.id">
            <!-- <div class="goods_wrap" v-if="item.goods.length > 0"> -->
            <!-- <div class="item" v-for="k in item.goods" :key="k.id"> -->
            <ms_item :itemData="item" @getData="getData" />
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="empty">
        <el-empty v-if="hot_goods.length == 0 && seckill_area.length == 0" :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
// import bigimg from "@/components/goodsItem/bigImg.vue";
import { DeepClone } from "@/utils/tool.js";
import ms_item from "./components/msItem.vue";
import tag_img from "@/assets/img/goods/tag_img.png";
// import floornav from "@/components/floornav.vue";
import { handleJump, to2DArray } from "@/utils";
export default {
  components: {
    // bigimg,
    ms_item,
    // floornav,
  },
  data() {
    return {
      handleJump,
      zone: [],
      activeData: [],
      limit_str: "",
      p: {
        kw: "",
        area_id: "",
        cate_id: "",
      },
      activeInd: 0,
      navActiveInd: 0,
      page: 1,
      page_size: 20,
      total: 0,
      pageName: this.$store.state.pageName,
      info: {
        adv_img: "",
      },
      tabList: [],
      // navList: [],
      msData: {},
      hot_area: [],
      hot_area_2d: [],
      hot_goods: [],
      seckill_area: [],
      tag_img: tag_img,
      area_id: "",
      param: {},
    };
  },
  created() {
    this.param = this.$route.query;
    this.getData();
    this.getADV();
    // this.getActiveCateTop();
    // this.getActiveCateSecond();
  },
  mounted() {
    setInterval(() => {
      for (var key in this.hot_area) {
        var rightTime = Math.floor((this.hot_area[key]["countdown_time"] * 1000 - Date.now()) / 1000);
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 60) % 60);
          var ss = Math.floor(rightTime % 60);
          this.hot_area[key]["djs"] = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        } else {
          this.hot_area[key]["djs"] = "已结束";
        }
      }
    }, 1000);
  },
  methods: {
    InitTime(endtime) {
      var dd,
        hh,
        mm,
        ss = null;
      var time = Math.floor((endtime * 1000 - Date.now()) / 1000);
      if (time <= 0) {
        return "结束";
      } else {
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time % 60);
        var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        return str;
      }
    },
    getData() {
      // console.log("传递的参数----", this.param);
      this.$api("goods.seckill", this.param).then((res) => {
        if (res.code == 200) {
          this.limit_str = res.data.limit_str;
          // this.hot_area = res.data.hot_area;
          // this.hot_goods = res.data.hot_goods;
          this.hot_goods = res.data.hot_goods.map((v) => {
            let num = ((Number(v.full_num) - Number(v.sale_num)) / Number(v.full_num)) * 100;
            // let num = (Number(v.sale_num) / Number(v.full_num)) * 100;
            this.$set(v, "percentage", num);
            return v;
          });
          if (this.hot_goods.length > 0) {
            this.hot_area_2d = to2DArray(this.hot_goods, 2);
          } else {
            this.hot_area_2d = [];
          }

          // let choosed = res.data.hot_area
          // this.seckill_area = res.data.seckill_area;
          this.seckill_area = res.data.seckill_area.map((item) => {
            // item.goods.map((v) => {
            let num = ((Number(item.full_num) - Number(item.sale_num)) / Number(item.full_num)) * 100;
            // let num = (Number(item.sale_num) / Number(item.full_num)) * 100;
            this.$set(item, "percentage", num);
            // return v;
            // });
            return item;
          });
          let arr = DeepClone(res.data.hot_area);
          arr.map((obj) => {
            this.$set(obj, "djs", this.InitTime(obj.countdown_time));
          });
          this.hot_area = arr;
          this.activeInd = res.data.hot_area.findIndex((item) => item.is_chosed == 101);
          // this.area_id = res.data.hot_area[this.activeInd].id;
          // this.getGoodsList();
        }
      });
    },

    getADV() {
      this.$api("zone.toolsBanner", { type: 5 }).then((res) => {
        if (res.code == 200) {
          this.info.adv_img = res.data.logo;
        }
      });
    },

    getGoodsList() {
      this.$api("goods.getGoods", { area_id: this.area_id }).then((res) => {
        if (res.code == 200) {
          this.total = res.data.count;
          // this.hot_goods = res.data.list
          this.hot_goods = res.data.list.map((v) => {
            let num = (Number(v.residue_num) / Number(v.full_num)) * 100;
            // console.log("百分比------", num);
            this.$set(v, "percentage", num);
            return v;
          });
          if (this.hot_goods.length > 0) {
            console.log("原数组----", this.hot_goods);
            this.hot_area_2d = to2DArray(this.hot_goods, 2);
            console.log("处理后的二维数组----", this.hot_area_2d);
          } else {
            this.hot_area_2d = [];
          }
        }
      });
    },
    changeTab(item, index) {
      this.activeInd = index;
      // this.area_id = item.id;
      // this.getGoodsList();
      this.param = {
        id: item.id,
      };
      this.getData();
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.regular_num,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        // this.addToCart
        this.$emit("getData");
        this.$store.dispatch("getBadge");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.activity_container {
  background-size: cover;
  .img {
    // width: 100%;
    // height: 220px;
  }
  .active-content {
    width: 100%;
    // padding-top: 30px;
    .head {
      width: 100%;
      width: 1200px;
      margin: 0 auto;
      background-color: red;
      .tab-box {
        // width: 1200px;
        // height: 80px;
        // margin: 0 auto;
        // display: flex;
        // justify-content: center;

        display: flex;
        background-color: red;
        padding: 10px 10px 0 10px;
        margin: 20px 0;
        justify-content: flex-start;
        .tab-item {
          // width: 300px;
          width: 260px;
          color: #fff;
          // font-size: 28px;
          // box-sizing: border-box;
          // padding: 0 6px;
          // line-height: 80px;
          text-align: center;
          cursor: pointer;
          .ms-time {
            font-size: 22px;
          }
          .ms-title {
            font-size: 14px;
          }
        }
        .active {
          color: red;
          background-color: #f6f6f6;
          width: 260px;
          // height: 70px;
          border-radius: 12px 12px 0 0;
        }
      }
    }
    .content-center {
      width: 1200px;
      margin: 0 auto;
      .swiper-box {
        background-image: url(../../assets/img/goods/bkmsBox_two.png);
        width: 1217px;
        height: 650px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        padding: 0 23px;
        box-sizing: border-box;
        position: relative;
        .limit-text {
          font-size: 20px;
          color: #373737;
          margin-top: 20px;
          text-align: center;
          position: absolute;
          bottom: 46px;
          left: 0;
          width: 100%;
        }
        .swiper-content {
          width: 1160px;
          height: 650px;
          position: absolute;
          top: 216px;
          left: 28px;
          .swiper-item {
            width: 100%;
            height: 100%;
            height: 325rpx;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .goods-item {
              width: 574px;
              height: 325px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              background-color: #ffd6c1;
              border-radius: 45px 30px 30px 30px;
              .goods-img {
                width: 299px;
                height: 289px;
                border-radius: 30px;
                margin-left: 5px;
              }
              .right-info {
                width: 250px;
                margin-left: 15px;
                .title-name {
                  font-size: 24px;
                  color: #f03627;
                  font-weight: 600;
                }
                .sub-text {
                  color: #4f4f4f;
                  font-size: 16px;
                  margin-top: 15px;
                }
                .two-row {
                  display: flex;
                  .btn_xl {
                    background-image: linear-gradient(to right, #ff512f 0%, #ff525c 51%, #ff512f 100%);
                    color: #fff;
                    padding: 1px 2px;
                    border-radius: 5px;
                    font-size: 15px;
                    display: flex;
                    margin-left: 10px;
                    align-items: center;
                    p {
                      font-size: 12px;
                      margin-top: 0;
                    }
                    .num {
                      background-color: #fff;
                      color: #f54c4c;
                      font-size: 12px;
                      padding: 0 4px;
                      height: 14px;
                      line-height: 14px;
                      border-radius: 3px;
                      margin-left: 5px;
                    }
                  }
                }
                .percentage-box {
                  width: 197px;
                  margin-top: 16px;
                }
                .ms-btn {
                  width: 196px;
                  min-width: 196px;
                  padding: 0 20px;
                  height: 40px;
                  margin-top: 20px;
                  cursor: pointer;
                  background-image: linear-gradient(0deg, #ee3121 0%, #f84a3f 100%), linear-gradient(#ffffff, #ffffff);
                  border-radius: 36px;
                  position: relative;
                  display: flex;
                }
                .ms-btn > span {
                  color: #fff;
                  vertical-align: bottom;
                }
                .ms-btn > span:nth-child(1) {
                  font-size: 12px;
                  margin-left: 20px;
                  line-height: 58px;
                }
                .ms-btn > span:nth-child(2) {
                  font-weight: 600;
                  font-size: 31px;
                }
                // .ms-btn > img {
                //   position: absolute;
                //   top: 7px;
                //   right: 19px;
                //   width: 28px;
                //   height: 28px;
                // }
                .cart-img {
                  position: absolute;
                  top: 7px;
                  right: 19px;
                  width: 28px;
                  height: 28px;
                }
              }
            }
          }
        }
      }
      .tag-box {
        height: 80px;
        width: 260px;
        margin: 30px auto 10px;
        position: relative;
        .tag-title {
          width: 100%;
          text-align: center;
          font-size: 30px;
          top: 0;
          left: 0;
          font-weight: bold;
          margin-top: 30px;
          position: absolute;
          color: #fff;
        }
      }
    }
  }

  .goods_wrap {
    // background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    // margin: 8px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    .item {
      margin-right: 12px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
/deep/.el-carousel__container {
  // display: none;
  height: 325px;
}
/deep/.hide-percentage .el-progress__text {
  display: none;
}
// ./deep/.el-progress-bar {
//   width: 197px;
// }
/deep/.el-progress-bar__innerText {
  display: none;
}
</style>
